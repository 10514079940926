import { LoadingButton } from '@mui/lab';
import { FormControl, Input, styled } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CharacterSelect from '../components/CharacterSelect';
import PageBottomRow from '../components/PageBottomRow';
import { VALIDATORS } from '../constants/validators';
import useGameManagerContext from '../hooks/useGameManagerContext';
import PropTypes from '../propTypes';
import { useGameId } from '../store/gameSlice';
import PageContainer from '../styled-components/PageContainer';
import getRandomInt from '../utils/getRandomInt';

const Container = styled(PageContainer)(({ theme }) => ({}));

export default function SelectNameAndCharacter({ isHost }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  // The color mode of the character
  const [characterColorModeDark, setCharacterColorModeDark] = useState(true);
  // The character index
  const [characterId, setCharacterId] = useState(getRandomInt(0, 10));

  const [loading, setLoading] = useState(false);
  const { createNewGame, joinGame } = useGameManagerContext();
  const navigate = useNavigate();
  const gameId = useGameId();

  const onFormSubmit = async (data) => {
    if (loading) {
      return;
    }

    setLoading(true);

    if (isHost) {
      const res = await createNewGame(
        data.name.trim(),
        characterId,
        characterColorModeDark ? 'dark' : 'light'
      );

      if (res) {
        navigate(`/game?gameId=${res}`);
      }

      setLoading(false);
      return;
    }

    await joinGame(
      gameId,
      data.name.trim(),
      characterId,
      characterColorModeDark ? 'dark' : 'light'
    );
  };

  return (
    <Container as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <CharacterSelect
        colorModeDark={characterColorModeDark}
        setColorModeDark={setCharacterColorModeDark}
        index={characterId}
        setIndex={setCharacterId}
      />
      <FormControl fullWidth>
        <Input
          placeholder="Enter a nickname"
          autoFocus
          disabled={loading}
          inputProps={{
            ...register('name', VALIDATORS.NAME),
          }}
        />
      </FormControl>
      <PageBottomRow>
        <LoadingButton
          fullWidth
          variant="contained"
          disabled={!isValid}
          loading={loading}
          type="submit"
        >
          Continue
        </LoadingButton>
      </PageBottomRow>
    </Container>
  );
}

SelectNameAndCharacter.propTypes = {
  isHost: PropTypes.bool,
};
