import { Stack, styled, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import getCharacterImage from '../utils/getCharacterImage';
import PlayerProfile from './PlayerProfile';

const RowContainer = styled(Stack)(({ theme }) => ({
  flex: '0 0 auto',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  minHeight: 125,
  width: '100%',
  overflowY: 'hidden',
  overflowX: 'auto',
}));

// Renders a list of players that are online in the game
export default function OnlinePlayersRow() {
  const theme = useTheme();
  const onlinePlayers = useSelector((state) => state.game.players.online);

  const transitions = useTransition(onlinePlayers, {
    keys: (doc) => doc.uid,
    from: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 20 },
    config: { duration: theme.transitions.duration.shortest },
  });

  return (
    <RowContainer direction="row" className="players-row" spacing={3}>
      {transitions((props, player, f, i) => (
        <animated.div style={props} key={player.uid}>
          <PlayerProfile
            src={getCharacterImage(player.characterMode, player.characterId)}
            name={player.name}
          />
        </animated.div>
      ))}
    </RowContainer>
  );
}
