import { Alert, Snackbar, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_MAX_WIDTH } from '../../constants';
import {
  closeNotification,
  useNotificationIsOpen,
  useNotificationMessage,
  useNotificationSeverity,
} from '../../store/notificationSlice';

export default function NotificationsController() {
  const theme = useTheme();

  const message = useNotificationMessage();
  const severity = useNotificationSeverity();
  const isOpen = useNotificationIsOpen();
  const dispatch = useDispatch();

  const offsetBottom = useSelector((state) => state.app.pagePaddingBottom);

  const onSnackbarClose = () => dispatch(closeNotification());

  return (
    <Snackbar
      open={isOpen}
      onClose={onSnackbarClose}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      sx={{
        marginBottom: `${offsetBottom}px`,
        bottom: '0 !important',
        minWidth: PAGE_MAX_WIDTH,
        maxWidth: PAGE_MAX_WIDTH,
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.down(PAGE_MAX_WIDTH + 60)]: {
          minWidth: '100%',
        },
      }}
    >
      <Alert
        onClose={onSnackbarClose}
        variant="filled"
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
