import { ArrowForwardIos, Download, Share } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  styled,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material';
import { get, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { useSprings } from 'react-spring';
import { createFileName, useScreenshot } from 'use-react-screenshot';
import {
  NOTIFICATION_SEVERITIES,
  PAGE_MAX_WIDTH,
} from '../../../constants/index';
import useDisplayNotification from '../../../hooks/useDisplayNotification';
import PropTypes from '../../../propTypes';
import { useGameId } from '../../../store/gameSlice';
import { realtimeDb } from '../../../utils/firebase';
import HighlightCard from './Card';

const HighlightsContainer = styled(SwipeableDrawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    minHeight: `calc(100vh - 110px)`,
    maxHeight: `calc(100vh - 110px)`,
    maxWidth: PAGE_MAX_WIDTH,
    left: '50%',
    marginLeft: -PAGE_MAX_WIDTH / 2,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.down(PAGE_MAX_WIDTH + 60)]: {
      left: 0,
      marginLeft: 0,
      maxWidth: '100%',
    },
  },

  '.cards-container': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4),
    position: 'relative',
    width: '100%',
    margin: 'auto',
    maxHeight: '100%',
    zIndex: -1,
  },

  '.bottom-row': {
    minHeight: 80,
    padding: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    right: 0,
    '.dot': {
      width: theme.spacing(1),
      height: theme.spacing(1),
      background: theme.palette.grey[800],
      borderRadius: '50%',

      '&.active': {
        background: theme.palette.primary.main,
      },
    },
  },
}));

const fn = (items, activeIndex, theme) => (i) => {
  const isActive = i === activeIndex;
  const diff = Math.abs(activeIndex - i);
  const hide = activeIndex - i > 1;

  return {
    immediate: (key) => ['zIndex'].includes(key),
    contentOpacity: isActive ? 1 : 0,
    backgroundColor: isActive
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    scale: 1 - diff * 0.15,
    zIndex: items.length - diff,
    y: (i - activeIndex) * 60,
    opacity: hide ? 0 : 1 - diff * 0.2 + 0.2,
    config: { duration: theme.transitions.duration.short },
  };
};

export default function Highlights({ open, onOpen, onClose }) {
  const [cards, setCards] = useState([]);
  const theme = useTheme();

  const gameId = useGameId();

  const [loading, setLoading] = useState(false);
  const displayNotification = useDisplayNotification();

  const [activeIndex, setActiveIndex] = useState(0);
  const [springs, api] = useSprings(
    cards.length,
    fn(cards, activeIndex, theme)
  );

  const onCardClick = (i) => {
    return () => setActiveIndex(i);
  };

  // Fetches all rounds of the game and sorts the answers in ascending order by votes
  const fetchAndSetItems = async () => {
    const rounds = await get(ref(realtimeDb, `games/${gameId}/rounds`));

    const roundAnswers = rounds.val().map(({ answers, questionText }) => {
      const entries = Object.entries(answers);
      const res = [];

      for (const [uid, answer] of entries) {
        res.push({
          uid,
          text: answer.text,
          votes: answer.votes ? Object.keys(answer.votes).length : 0,
          questionText,
        });
      }

      return res;
    });

    let answers = [];

    for (const item of roundAnswers) {
      answers.push(...item);
    }

    answers.sort((a, b) => {
      const votesA = a.votes;
      const votesB = b.votes;

      if (votesA > votesB) {
        return -1;
      }

      if (votesA < votesB) {
        return 1;
      }

      return 0;
    });

    setCards(answers.slice(0, 4));
  };

  const [, takeScreenshot] = useScreenshot({
    type: 'image/png',
    quality: 1.0,
  });

  const createScreenshot = () => {
    return takeScreenshot(document.body);
  };

  // Create and download screenshot
  const downloadHighlight = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const res = await createScreenshot();

    setLoading(false);

    const a = document.createElement('a');
    a.href = res;
    a.download = createFileName('jpg', 'ucked');
    a.click();
  };

  // Share screenshot
  const shareHighlight = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const res = await createScreenshot();

    setLoading(false);

    const blob = await (await fetch(res)).blob();
    console.log(blob);

    if (navigator.share) {
      await navigator.share({
        files: [
          new File([blob], 'image.png', {
            type: blob.type,
          }),
        ],
      });
    } else {
      await navigator.clipboard.write([
        new window.ClipboardItem({ [blob.type]: blob }),
      ]);
      displayNotification(
        'Highlight has been copied to clipboard',
        NOTIFICATION_SEVERITIES.INFO,
        {}
      );
    }
  };

  const activeCard = cards.length > 0 ? cards[activeIndex] : null;

  useEffect(() => {
    api.start(fn(cards, activeIndex, theme));
  }, [activeIndex]);

  useEffect(() => {
    fetchAndSetItems();
  }, []);

  return (
    <HighlightsContainer
      open={open}
      anchor="bottom"
      onOpen={onOpen}
      onClose={onClose}
      PaperProps={{ elevation: 0 }}
      BackdropProps={{ sx: { opacity: '0 !important' } }}
    >
      <Box
        display="flex"
        justifyContent="center"
        onClick={onClose}
        data-html2canvas-ignore
      >
        <IconButton sx={{ mt: -4, mb: 2 }}>
          <ArrowForwardIos sx={{ transform: 'rotate(90deg)' }} />
        </IconButton>
      </Box>
      <Typography variant="h1" textAlign="center">
        {activeCard?.questionText}
      </Typography>
      <Box className="cards-container">
        {springs.map((styles, i) => (
          <HighlightCard
            uid={cards[i].uid}
            text={cards[i].text}
            style={styles}
            onClick={onCardClick(i)}
            key={`highlight-card-${i}`}
          />
        ))}
      </Box>

      <Stack
        className="bottom-row"
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <div></div>

        <Stack direction="row" spacing={1.5} className="dots-nav">
          {cards.map((card, i) => (
            <Box
              className={`dot ${i === activeIndex ? 'active' : ''}`}
              key={`card-dot-${i}`}
            />
          ))}
        </Stack>

        <div></div>
      </Stack>
      <Stack
        className="bottom-row"
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        data-html2canvas-ignore
      >
        <IconButton
          color="primary"
          edge="start"
          size="large"
          sx={{ visibility: 'none', opacity: 0 }}
          // onClick={downloadHighlight}
          // disabled={loading}
        >
          <Download />
        </IconButton>

        <Stack direction="row" spacing={1.5} className="dots-nav">
          {cards.map((card, i) => (
            <Box
              className={`dot ${i === activeIndex ? 'active' : ''}`}
              onClick={onCardClick(i)}
              key={`card-dot-${i}`}
            />
          ))}
        </Stack>

        <IconButton
          color="primary"
          edge="end"
          size="large"
          disabled={loading}
          onClick={shareHighlight}
        >
          <Share />
        </IconButton>
      </Stack>
    </HighlightsContainer>
  );
}

Highlights.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};
