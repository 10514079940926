import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PageBottomRow from '../../components/PageBottomRow';
import { VALIDATORS } from '../../constants/validators';
import useGameManagerContext from '../../hooks/useGameManagerContext';
import { setLoading, useGameId, useLoading } from '../../store/gameSlice';

// A host view to enter names
export default function PlayerNames() {
  const { register, handleSubmit, reset, watch, formState } = useForm({
    reValidateMode: 'onChange',
  });
  const gameId = useGameId();
  const loading = useLoading();
  const gameManager = useGameManagerContext();
  const dispatch = useDispatch();

  const onNameFormSubmit = async (data) => {
    const names = data.names.trim();

    if (loading) {
      return;
    }

    dispatch(setLoading(true));

    // Set names
    await gameManager.setNames(
      gameId,
      // Split the string by comma and remove any empty strings
      names
        .split(',')
        .filter((name) => name.trim() !== '')
        .map((name) => name.trim())
    );

    dispatch(setLoading(false));
  };
  console.log(formState);

  return (
    <>
      <Typography
        variant="h1"
        className="page-title"
        sx={{
          textAlign: 'left',
        }}
      >
        To enter the lobby:
        <br />
        <br />
        You need to enter your name and your friends names separated by a comma
      </Typography>

      <FormControl
        fullWidth
        id="namesForm"
        variant="standard"
        component="form"
        onSubmit={handleSubmit(onNameFormSubmit)}
        sx={{ mb: 4 }}
        disabled={loading}
        error={!!formState.errors.names}
      >
        <Input
          placeholder="Daniella, Zac, Liam, Abby, Callum"
          autoFocus
          disabled={loading}
          inputProps={{
            ...register('names', VALIDATORS.NAMES),
          }}
          error={!!formState.errors.names}
        />
        {
          // Show error message if there is one
          formState.errors.names && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {formState.errors.names.message}
            </FormHelperText>
          )
        }
      </FormControl>

      <PageBottomRow>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              variant="contained"
              type="submit"
              form="namesForm"
              loading={loading}
            >
              Done
            </LoadingButton>
          </Grid>
        </Grid>
      </PageBottomRow>
    </>
  );
}
