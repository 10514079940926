import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter } from 'react-router-dom';
import NotificationsController from './components/controllers/NotificationsController';
import AppRoutes from './routes';
import store from './store';
import AppStyles from './styles/AppStyles';
import theme from './theme';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="App">
            <NotificationsController />
            <AppStyles />
            <AppRoutes />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
