import { QuestionMark } from '@mui/icons-material';
import { IconButton, Stack, styled, Typography } from '@mui/material';
import { useState } from 'react';
import OnlinePlayersRow from '../../components/OnlinePlayersRow';
import { useGameData, useIsHost } from '../../store/gameSlice';
import PageContainer from '../../styled-components/PageContainer';
import TutorialDrawer from '../TutorialDrawer';
import LobbyQuestions from './LobbyQuestions';
import PlayerNames from './PlayerNames';

const LobbyContainer = styled(PageContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '.player-name': {
    minHeight: 60,
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',

    '.name': {
      marginRight: theme.spacing(2),
    },
  },
}));

export default function Lobby() {
  const [isTutorialDrawerOpen, setIsTutorialDrawerOpen] = useState(false);

  const gameData = useGameData();
  const isHost = useIsHost();

  return (
    <LobbyContainer>
      <TutorialDrawer
        open={isTutorialDrawerOpen}
        onClose={() => setIsTutorialDrawerOpen(false)}
        onOpen={() => setIsTutorialDrawerOpen(true)}
      />
      {gameData.namesSet ? (
        <OnlinePlayersRow />
      ) : (
        <Stack direction="row" justifyContent="space-between" pb={5}>
          <div></div>
          <IconButton
            color="primary"
            className="tutorial-button"
            size="large"
            type="button"
            variant="outlined"
            onClick={() => setIsTutorialDrawerOpen(true)}
          >
            <QuestionMark />
          </IconButton>
        </Stack>
      )}

      {!gameData.namesSet && (
        <>
          {isHost && <PlayerNames />}
          {!isHost && <Typography>Waiting for the host...</Typography>}
        </>
      )}
      {gameData.namesSet && <LobbyQuestions />}
    </LobbyContainer>
  );
}
