import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    uid: null,
    userIsLoading: true,
    userError: null,
  },
  reducers: {
    setUid: (state, action) => ({
      ...state,
      uid: action.payload,
    }),
    setUserIsLoading: (state, action) => ({
      ...state,
      userIsLoading: action.payload,
    }),
    setUserError: (state, action) => ({
      ...state,
      userError: action.payload,
    }),
  },
});

export const { setUid, setUserIsLoading, setUserError } = authSlice.actions;

const authReducer = authSlice.reducer;

export const useUid = () => useSelector((state) => state.auth.uid);

export default authReducer;
