import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    pagePaddingBottom: 0,
  },
  reducers: {
    setPagePaddingBottom: (state, action) => ({
      ...state,
      pagePaddingBottom: action.payload,
    }),
  },
});

export const { setPagePaddingBottom } = appSlice.actions;

const appReducer = appSlice.reducer;

export default appReducer;
