import { onDisconnect, onValue, ref, update } from 'firebase/database';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUid } from '../../../store/authSlice';
import { useGameId } from '../../../store/gameSlice';
import { realtimeDb } from '../../../utils/firebase';
import useAllPlayers from '../useAllPlayers';

export default function usePlayerOnlineStatusController() {
  const uid = useUid();
  const gameId = useGameId();
  const players = useAllPlayers();
  const playerData = useSelector((state) => state.game.playerData);

  const isInPlayers = useMemo(() => {
    if (!uid) {
      return false;
    }

    return !!players.find((player) => player.uid === uid);
  }, [players, uid]);

  const isPlayerOnline = playerData?.online && isInPlayers;

  useEffect(() => {
    const updatePlayerStatus = async () => {
      const playerStatusRef = ref(realtimeDb, `games/${gameId}/players/${uid}`);

      onValue(ref(realtimeDb, '.info/connected'), (snapshot) => {
        if (snapshot.val() === false) {
          return;
        }

        onDisconnect(playerStatusRef)
          .update({ online: false })
          .then(() => {
            update(playerStatusRef, { online: true });
          });
      });
    };

    if (!isInPlayers) {
      return;
    }

    updatePlayerStatus();
  }, [isInPlayers, isPlayerOnline]);
}
