import { createTheme } from '@mui/material';

const FONT_FAMILY = `'Cabinet Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI',
					'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
					'Helvetica Neue', sans-serif`;

export const COLORS = {
  YELLOW: '#F4DC06',
  BLACK: '#0C0C0C',
  PAPER: '#242424',
  GREY: '#838383',
  LIGHT_GREY: '#EFEFEF',
  DARK_GREY: '#494949',
};

let theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: COLORS.BLACK,
      paper: COLORS.PAPER,
    },
    primary: { main: COLORS.YELLOW },
    text: { primary: COLORS.LIGHT_GREY },
    divider: COLORS.DARK_GREY,
    grey: { 800: COLORS.GREY },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontWeightRegular: 700,
    fontWeightMedium: 800,
    fontWeightBold: 900,
    h1: {
      fontSize: '1.5rem',
      fontWeight: 800,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 800,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 800,
      lineHeight: 1.22,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 800,
      color: COLORS.GREY,
    },
  },
  shape: {
    borderRadius: 40,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          background: COLORS.PAPER,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.rounded': {
            borderRadius: '50%',
            backgroundColor: COLORS.YELLOW,
            color: COLORS.BLACK,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            border: '2px solid',
            borderColor: 'inherit',
            fontSize: '1rem',
            padding: theme.spacing(0.5),

            svg: {
              fontSize: '1rem',
            },
          }),
        },
      ],
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '.MuiInput-input': {
            fontSize: '1.25rem',
            fontWeight: 800,
            textAlign: 'center',
          },
          '&::before': {
            borderBottom: `2px solid ${COLORS.LIGHT_GREY} !important`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: 54,
          fontSize: 18,

          '&.Mui-disabled': {
            color: COLORS.GREY,
            border: `2px solid ${COLORS.GREY}`,
            background: 'transparent',
          },
        },
      },
    },
  },
});

// theme = responsiveFontSizes(theme);

export default theme;
