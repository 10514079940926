import { useGameData } from '../../store/gameSlice';

export default function useIsLastRound() {
  const gameData = useGameData();

  if (!gameData) {
    return false;
  }

  return parseInt(gameData.currentRound) + 1 === gameData.maxRounds;
}
