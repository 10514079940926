import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import authReducer from './authSlice';
import gameReducer from './gameSlice';
import notificationReducer from './notificationSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    game: gameReducer,
    auth: authReducer,
    notification: notificationReducer,
  },
});

export default store;
