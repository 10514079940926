import { Stack, styled, useTheme } from '@mui/material';
import { animated, useTransition } from 'react-spring';
import PropTypes from '../propTypes';
import getCharacterImage from '../utils/getCharacterImage';
import PlayerProfile from './PlayerProfile';

const RowContainer = styled(Stack, {
  shouldForwardProp: (prop) => !['centered'].includes(prop),
})(({ theme, centered }) => ({
  width: centered ? 'fit-content' : '100%',
  margin: 'auto',
  maxWidth: '100%',
  overflowY: 'hidden',
  overflowX: 'auto',
  display: 'flex',
  paddingBottom: theme.spacing(1),
}));

export default function PlayersRow({
  centered = false,
  players,
  showStatus = false,
  ...props
}) {
  const theme = useTheme();

  const transitions = useTransition(players, {
    keys: (doc) => doc.uid,
    from: { opacity: 0, y: 20 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 20 },
    config: { duration: theme.transitions.duration.shortest },
  });
  return (
    <RowContainer direction="row" spacing={2} centered={centered} {...props}>
      {transitions((props, player, f, i) => (
        <animated.div style={props} key={player.uid}>
          <PlayerProfile
            src={getCharacterImage(player.characterMode, player.characterId)}
            name={player.name}
            status={showStatus ? player.status : null}
            small
          />
        </animated.div>
      ))}
    </RowContainer>
  );
}

PlayersRow.propTypes = {
  centered: PropTypes.bool,
  players: PropTypes.array,
  showStatus: PropTypes.bool,
};
