import { Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  Stack,
  styled,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { PAGE_MAX_WIDTH } from '../constants';
import PropTypes from '../propTypes';

const DrawerContainer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) => !['isLastRound'].includes(prop),
})(({ theme, isLastRound }) => ({
  '.MuiDrawer-paper': {
    minHeight: isLastRound ? `calc(100vh - 110px)` : '76vh',
    maxHeight: isLastRound ? `calc(100vh - 110px)` : '76vh',
    maxWidth: PAGE_MAX_WIDTH,
    left: '50%',
    top: 'initial',
    bottom: 0,
    marginLeft: -PAGE_MAX_WIDTH / 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTop: '4px solid',
    borderTopColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden',

    [theme.breakpoints.down(PAGE_MAX_WIDTH + 60)]: {
      left: 0,
      marginLeft: 0,
      maxWidth: '100%',
    },
  },

  '.carousel-container': {
    overflow: 'auto',
    flex: 1,

    '.carousel-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',

      '.carousel-slider': {
        height: '100%',
        flex: 1,
      },

      '.control-dots': {
        bottom: theme.spacing(-2),

        '.dot': {
          marginLeft: 0,
          marginRight: 0,
          width: theme.spacing(1),
          height: theme.spacing(1),

          '&.selected': {
            backgroundColor: theme.palette.primary.main,
          },

          '&:not(:last-child)': {
            marginRight: theme.spacing(1),
          },
        },
      },
    },
  },

  '.body': {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    img: {
      width: '100%',

      '&.header-image': {
        width: '80%',
        marginBottom: theme.spacing(2),
      },
    },

    '.MuiTypography-root': {
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 800,
      lineHeight: 1.3,
    },

    ul: {
      listStyleType: 'disc',
    },
  },

  '.close-button': {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    flex: '0 0 auto',
    zIndex: 1,
  },
}));

export default function TutorialDrawer({ open, onOpen, onClose }) {
  return (
    <DrawerContainer
      open={open}
      anchor="bottom"
      onOpen={onOpen}
      onClose={onClose}
      PaperProps={{ elevation: 0 }}
      isLastRound
    >
      <IconButton
        variant="outlined"
        color="primary"
        onClick={onClose}
        className="close-button"
      >
        <Close />
      </IconButton>

      <Box className="carousel-container">
        <Carousel showThumbs={false} showStatus={false} showArrows={false}>
          <Stack className="body">
            <img
              src="/images/tutorial/header.png"
              className="header-image"
              alt="characters"
            />

            <Typography>
              Ucked is a free party game that we created because the other games
              are trash
              <br />
              <br />
              Honestly if you:
            </Typography>

            <ul>
              <li>
                <Typography>enjoy playing cards against humanity</Typography>
              </li>
              <li>
                <Typography>are sensitive</Typography>
              </li>
            </ul>

            <Typography>
              <br />
              Then you can fuck off now, this is not the game for you
              <br />
              <br />
              Otherwise, grab some friends, a bunch of drinks and give this game
              a shot
            </Typography>
          </Stack>

          <Stack className="body">
            <Typography mb={4}>
              Instructions:
              <br />
              <br />
              1. enter a username
              <br />
              2. enter your friends real names
              <br />
              3. share the game link with 2+ friends
              <br />
              4. answer the lobby questions to teach the game about your group
              <br />
              5. play ucked by roasting your friends and getting crowned the
              ultimate winner
              <br />
              <br />
              The lobby looks like:
            </Typography>

            <img src="/images/tutorial/lobby.png" />
          </Stack>

          <Stack className="body">
            <Typography mb={4}>
              When the game starts there are 8 rounds of questions based on what
              we learned about you in the lobby.
              <br />
              <br />
              Answer them with no restrictions and your answers will be
              anonymous
              <br />
              <br />
              Win by getting the most votes from everyone playing
              <br />
              <br />
              The rounds look like:
            </Typography>

            <img src="/images/tutorial/round.png" />
          </Stack>
          <Stack className="body">
            <Typography mb={4}>
              Check the results, the winner gives out drinks
              <br />
              <br />
              After the 8 rounds you can check a final score and view the games
              highlights
              <br />
              <br />
              The results page look like:
            </Typography>

            <img src="/images/tutorial/results.png" />

            <Typography mt={4}>
              If you have any suggestions our email is{' '}
              <Link href="mailto:info@ucked.co" color="primary">
                info@ucked.co
              </Link>
            </Typography>
          </Stack>
        </Carousel>
      </Box>
    </DrawerContainer>
  );
}

TutorialDrawer.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};
