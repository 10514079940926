import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import useAllPlayers from '../hooks/game/useAllPlayers';
import useIsLastRound from '../hooks/game/useIsLastRound';
import usePlayerDataByUid from '../hooks/usePlayerData';
import PropTypes from '../propTypes';
import StyledPaper from '../styled-components/StyledPaper';
import getCharacterImage from '../utils/getCharacterImage';
import PlayersRow from './PlayersRow';

const SummaryBlockContainer = styled(StyledPaper)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  textAlign: 'left',

  '.author-info': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),

    '.image-container': {
      maxWidth: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(3),

      img: {
        width: '300%',
      },
    },
  },
}));

export default function SummaryBlock({ text, uid, votes }) {
  const allPlayers = useAllPlayers();
  const isLastRound = useIsLastRound();

  const playerAuthorData = usePlayerDataByUid(uid);

  const players = useMemo(() => {
    return allPlayers.filter((player) => votes?.hasOwnProperty(player.uid));
  }, [allPlayers]);

  const votesLength = votes ? Object.keys(votes).length : 0;

  return (
    <SummaryBlockContainer elevation={0}>
      <Box className="author-info">
        <Box className="image-container">
          <img
            src={getCharacterImage(
              playerAuthorData?.characterMode,
              playerAuthorData?.characterId
            )}
            alt="character"
          />
        </Box>
        <Typography variant="h3">{playerAuthorData?.name}</Typography>
      </Box>
      <Typography variant="h4">{text}</Typography>

      <Divider sx={{ mt: 2, mb: 1.5 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">Votes</Typography>
        <Stack direction="row" alignItems="baseline" spacing={0.5}>
          <Typography variant="subtitle1">+{votesLength}</Typography>
          <Typography variant="subtitle1" color="primary.dark">
            {isLastRound && votesLength > 0 && 'x2'}
          </Typography>
        </Stack>
      </Stack>
      {votesLength > 0 && <PlayersRow players={players} spacing={3} />}
    </SummaryBlockContainer>
  );
}

SummaryBlock.propTypes = {
  text: PropTypes.string,
  uid: PropTypes.string,
  votes: PropTypes.object,
  isLastRound: PropTypes.bool,
};
