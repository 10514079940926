import { LoadingButton } from '@mui/lab';
import { Box, Grid, Paper, styled, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageBottomRow from '../../components/PageBottomRow';
import PlayersRow from '../../components/PlayersRow';
import RoundProgressTracker from '../../components/RoundProgressTracker';
import { PLAYER_STATUSES } from '../../constants';
import useButtonHighlight from '../../hooks/useButtonHighlight';
import useGameManagerContext from '../../hooks/useGameManagerContext';
import usePlayersWaitingOn from '../../hooks/usePlayersWaitingOn';
import { useUid } from '../../store/authSlice';
import {
  setLoading,
  useCurrentRound,
  useGameData,
  useGameId,
  useLoading,
  usePlayerData,
} from '../../store/gameSlice';
import PaperButton from '../../styled-components/PaperButton';

const GameVoteContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',

  '.answer': {
    '&.inactive': {
      opacity: 0.5,
    },
    '&.active': {
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
  },
}));

export default function RoundVote() {
  const currentRound = useCurrentRound();
  const loading = useLoading();

  const dispatch = useDispatch();
  const [selectedAnswerUid, setSelectedAnswerUid] = useState(null);
  const uid = useUid();
  const gameId = useGameId();
  const playerData = usePlayerData();
  const gameData = useGameData();

  const { voteAnswer } = useGameManagerContext();
  const highlight = useButtonHighlight('game', 3, 1000);

  const { waitingOnPlayers, displayPlayers } = usePlayersWaitingOn();

  const answers = useMemo(() => Object.entries(currentRound.answers), []);

  const onAnswerClick = (uid) => {
    return () => {
      if (loading) {
        return;
      }

      setSelectedAnswerUid((prev) => (prev === uid ? null : uid));
    };
  };

  const onAnswerSend = async () => {
    if (loading || !selectedAnswerUid) {
      return;
    }

    dispatch(setLoading(true));

    const res = await voteAnswer({
      gameId,
      selectedAnswerUid,
      uid,
      currentRound: gameData.currentRound,
    });

    dispatch(setLoading(false));
  };

  const selectedAnswerText = useMemo(() => {
    if (!currentRound) {
      return '';
    }
    let res = '';

    for (const [, { text, votes }] of Object.entries(currentRound.answers)) {
      if (!votes) {
        continue;
      }

      if (votes.hasOwnProperty(uid)) {
        res = text;
        break;
      }
    }

    return res;
  }, [currentRound?.answers]);

  const isReady = playerData.status === PLAYER_STATUSES.VOTED_ANSWER;

  return (
    <GameVoteContainer selected={Boolean(selectedAnswerUid)}>
      <Typography variant="h1" mb={4}>
        {currentRound.questionText}
      </Typography>

      <Box display="flex" justifyContent="center" mb={4}>
        <RoundProgressTracker activeIndex={1} bars={2} width="55%" />
      </Box>

      {isReady ? (
        <>
          <Box mb={4}>
            <Typography variant="h3" color="primary" mb={2}>
              Your choice:
            </Typography>

            <Paper sx={{ p: 2 }}>
              <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
                {selectedAnswerText}
              </Typography>
            </Paper>
          </Box>
          {displayPlayers && (
            <>
              <Typography variant="h3" mb={2}>
                Waiting on:
              </Typography>

              <PlayersRow
                centered
                players={waitingOnPlayers}
                showStatus
                sx={{ mb: 4 }}
              />
            </>
          )}
        </>
      ) : (
        <Grid container spacing={1}>
          {answers.map(([uid, answer], i) => (
            <Grid key={`answer-${uid}`} item xs={6}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  maxHeight: 'initial',
                  textAlign: 'center',
                }}
                highlight={i === 0 ? highlight : false}
                component={PaperButton}
                onClick={onAnswerClick(uid)}
                className={`answer ${
                  selectedAnswerUid === null
                    ? ''
                    : selectedAnswerUid === uid
                    ? 'active'
                    : 'inactive'
                }`}
              >
                <Typography variant="h4" width="100%">
                  {answer.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {!isReady && (
        <PageBottomRow>
          <LoadingButton
            variant="contained"
            fullWidth
            loading={loading}
            disabled={!selectedAnswerUid}
            onClick={onAnswerSend}
          >
            Send
          </LoadingButton>
        </PageBottomRow>
      )}
    </GameVoteContainer>
  );
}
