import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageBottomRow from '../../components/PageBottomRow';
import PlayersGrid from '../../components/PlayersGrid';
import useGameManagerContext from '../../hooks/useGameManagerContext';
import { useUid } from '../../store/authSlice';
import {
  setLoading,
  useGameData,
  useGameId,
  useIsHost,
  useLoading,
  usePlayerData,
  useQuestionsStats,
} from '../../store/gameSlice';

export default function LobbyQuestions() {
  const dispatch = useDispatch();

  const [questionLoading, setQuestionLoading] = useState(false);
  const uid = useUid();

  const [question, setQuestion] = useState({ id: null, question: null });

  const gameData = useGameData();
  const questionsStats = useQuestionsStats();
  const playerData = usePlayerData();
  const gameId = useGameId();
  const isHost = useIsHost();
  const loading = useLoading();

  const {
    getLobbyQuestion,
    answerLobbyQuestion,
    inviteToGame,
    startGameTimer,
  } = useGameManagerContext();

  const hasQuestions =
    !playerData?.lobbyAnswers ||
    Object.keys(playerData.lobbyAnswers).length <
      questionsStats.totalPersonalQuestions;

  // Get next lobby question
  const getNextQuestion = async (additionalQuestionId = null) => {
    const totalQuestions = questionsStats.totalPersonalQuestions ?? 0;

    let lobbyAnswersLength = playerData.lobbyAnswers
      ? Object.keys(playerData.lobbyAnswers).length
      : 0;

    if (additionalQuestionId) {
      lobbyAnswersLength += 1;
    }

    if (playerData.lobbyAnswers && lobbyAnswersLength === totalQuestions) {
      setQuestion({ id: null, question: null });
      dispatch(setLoading(false));
      return;
    }

    setQuestionLoading(true);

    let arr = playerData.lobbyAnswers
      ? Object.keys(playerData.lobbyAnswers)
      : [];

    const res = await getLobbyQuestion(
      totalQuestions,
      arr,
      additionalQuestionId
    );

    if (res) {
      setQuestion(res);
    }

    setQuestionLoading(false);
  };

  // Send answer to the lobby question
  const onNameClick = async (name) => {
    if (questionLoading) {
      return;
    }

    if (JSON.parse(localStorage.getItem('lobby')) !== true) {
      localStorage.setItem('lobby', true);
    }

    setQuestionLoading(true);

    const res = await answerLobbyQuestion({
      name,
      questionId: question.id,
      gameId,
      uid,
    });

    if (res) {
      return getNextQuestion(question.id);
    }

    setQuestionLoading(false);
  };

  const onStartClick = async () => {
    if (loading) {
      return;
    }

    dispatch(setLoading(true));

    await startGameTimer(gameId);

    dispatch(setLoading(false));
  };

  useEffect(() => {
    getNextQuestion();
  }, []);

  return (
    <>
      {question.id && !questionLoading && (
        <>
          <Typography variant="h5" className="page-title">
            {question.question}
          </Typography>

          <PlayersGrid
            players={gameData.names}
            onNameClick={(name) => !loading && onNameClick(name)}
          />
          <Button
            fullWidth
            sx={{ borderRadius: 0, mt: 1 }}
            onClick={() => onNameClick('')}
            disabled={loading}
          >
            skip
          </Button>
        </>
      )}
      {!hasQuestions && (
        <Typography variant="h5" className="page-title">
          It's time to start the game!
        </Typography>
      )}

      <PageBottomRow>
        <Grid container spacing={2}>
          <Grid item xs={isHost ? 6 : 12}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => inviteToGame(gameData.names)}
              disabled={loading}
            >
              Invite
            </Button>
          </Grid>
          {isHost && (
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={onStartClick}
                loading={loading}
              >
                Start
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </PageBottomRow>
    </>
  );
}
