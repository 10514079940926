import { Box, CircularProgress } from '@mui/material';

export default function PageLoader() {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={50} />
    </Box>
  );
}
