import { styled } from '@mui/material';
import { HEADER_WIDTH, PAGE_MAX_WIDTH } from '../constants';

const PageContainer = styled('div')(({ theme }) => ({
  maxWidth: PAGE_MAX_WIDTH,
  maxHeight: '100%',
  height: 'calc(100vh - 260px)',
  margin: 'auto',
  textAlign: 'center',
  overflow: 'hidden',

  '.page-title': {
    marginBottom: '1.5rem',
  },

  [theme.breakpoints.down(HEADER_WIDTH + 32)]: {
    maxWidth: '100%',
  },
}));

export default PageContainer;
