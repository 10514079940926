import { ref } from 'firebase/database';
import { useEffect } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useDispatch } from 'react-redux';
import {
  setCurrentRound,
  setLoadingState,
  useGameData,
  useGameId,
} from '../../../store/gameSlice';
import { realtimeDb } from '../../../utils/firebase';

export default function useCurrentRoundController() {
  const gameId = useGameId();
  const gameData = useGameData();
  const dispatch = useDispatch();

  const [currentRound, currentRoundLoading] = useObjectVal(
    ref(realtimeDb, `games/${gameId}/rounds/${gameData?.currentRound}`)
  );

  useEffect(() => {
    if (!currentRound) {
      return dispatch(setCurrentRound(null));
    }

    dispatch(
      setCurrentRound({
        ...currentRound,

        // Randomize the order of answers
        ...(currentRound.answers && {
          answers: Object.entries(currentRound.answers)
            .map(([key, value]) => ({ key, value }))
            .sort(() => Math.random() - 0.5)
            .reduce((acc, { key, value }) => {
              acc[key] = value;
              return acc;
            }, {}),
        }),
      })
    );
  }, [currentRound]);

  useEffect(() => {
    dispatch(
      setLoadingState({
        key: 'currentRound',
        value: currentRoundLoading,
      })
    );
  }, [currentRoundLoading]);
}
