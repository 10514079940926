import { logEvent } from 'firebase/analytics';
import { get, increment, ref, runTransaction, update } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';
import { createContext, useCallback } from 'react';
import { ERRORS, NOTIFICATION_SEVERITIES, PLAYER_STATUSES } from '../constants';
import useDisplayNotification from '../hooks/useDisplayNotification';
import { analytics, functions, realtimeDb } from '../utils/firebase';
import getRandomInt from '../utils/getRandomInt';

// A game manager context with methods to interact with the game
export const GameManagerContext = createContext(null);

export function GameManagerContextProvider({ children }) {
  const displayNotification = useDisplayNotification();

  // Create a new game with the host's player data
  const createNewGame = useCallback(
    async (name, characterId, characterMode) => {
      const createNewGameFunctions = httpsCallable(functions, 'createNewGame');

      try {
        const res = await createNewGameFunctions({
          name,
          characterId,
          characterMode,
        });

        logEvent(analytics, 'game_create', {});
        // Return id of the new game to navigate the user
        return res.data.id;
      } catch (e) {
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Resets the game
  const resetGame = useCallback(
    async (gameId) => {
      const resetGameFunctions = httpsCallable(functions, 'resetGame');

      try {
        await resetGameFunctions({
          gameId,
        });

        logEvent(analytics, 'game_restart', {});
        return true;
      } catch (e) {
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Join a game by gameId with player's data
  const joinGame = useCallback(
    async (gameId, name, characterId, characterMode) => {
      const joinGameFunctions = httpsCallable(functions, 'joinGame');

      try {
        await joinGameFunctions({
          name,
          characterId,
          characterMode,
          gameId,
        });

        return true;
      } catch (e) {
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Set names during the creation of the game (lobby)
  const setNames = useCallback(
    async (gameId, names) => {
      const setNamesFunctions = httpsCallable(functions, 'setNames');

      try {
        await setNamesFunctions({
          gameId,
          names,
        });

        logEvent(analytics, 'game_lobby_set_names', {
          namesCount: names.length,
        });
        return true;
      } catch (e) {
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Start a game
  const startGame = useCallback(
    async (gameId, { namesCount, playersCount, onlinePlayersCount }) => {
      const startGameFunctions = httpsCallable(functions, 'startGame');

      try {
        await startGameFunctions({
          gameId,
        });

        logEvent(analytics, 'game_start', {
          namesCount,
          playersCount,
          onlinePlayersCount,
        });
        return true;
      } catch (e) {
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Get random lobby question
  const getLobbyQuestion = useCallback(
    async (
      totalPersonalQuestions,
      answeredPersonalQuestions,
      additionalQuestionId
    ) => {
      // Check if the question with this id has already been answered
      const isIdInAnsweredQuestions = () =>
        !!answeredPersonalQuestions.find((id) => id === '' + questionId) ||
        '' + questionId === additionalQuestionId;

      let questionId = getRandomInt(0, totalPersonalQuestions - 1);

      // While the question is in answered
      while (isIdInAnsweredQuestions()) {
        // Get new question id
        questionId = getRandomInt(0, totalPersonalQuestions - 1);
      }

      try {
        // Get the question
        const res = await get(
          ref(realtimeDb, `questions/personal/${questionId}`)
        );

        // Return its lobby questions and id
        return { question: res.val().lobby, id: res.key };
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Answer lobby question
  const answerLobbyQuestion = useCallback(
    async ({ name, questionId, gameId, uid }) => {
      try {
        update(ref(realtimeDb, `games/${gameId}/players/${uid}/lobbyAnswers`), {
          [questionId]: true,
        });

        if (name) {
          await update(
            ref(
              realtimeDb,
              `games/${gameId}/answeredPersonalQuestions/${questionId}`
            ),
            {
              [name]: increment(1),
            }
          );
        }

        return true;
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Create and copies (or shares) the invite link for the user
  // If provided uses names array to append the names in the invite
  const inviteToGame = useCallback(
    async (names) => {
      if (navigator.share) {
        await navigator.share({
          title: window.location.href,
          text: window.location.href,
          url: window.location.href,
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        displayNotification('Link copied', NOTIFICATION_SEVERITIES.INFO, {});
      }
    },
    [displayNotification]
  );

  // Start game start timer
  const startGameTimer = useCallback(
    async (gameId) => {
      const startGameTimerFunctions = httpsCallable(
        functions,
        'startGameTimer'
      );

      try {
        await startGameTimerFunctions({ gameId });

        return true;
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Get next round
  const nextRound = useCallback(
    async (gameId, increment = false) => {
      const nextRoundFunctions = httpsCallable(functions, 'nextRound');

      try {
        await nextRoundFunctions({ gameId, increment });

        return true;
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Send an answer to the round question
  const answerGameQuestion = useCallback(
    async ({ gameId, currentRound, answer, uid }) => {
      try {
        await update(
          ref(realtimeDb, `games/${gameId}/rounds/${currentRound}/answers`),
          {
            [uid]: {
              text: answer,
              votes: [],
            },
          }
        );

        await update(ref(realtimeDb, `games/${gameId}/players/${uid}`), {
          status: PLAYER_STATUSES.SENT_ANSWER,
        });

        return true;
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Vote for round question
  const voteAnswer = useCallback(
    async ({ gameId, currentRound, uid, selectedAnswerUid }) => {
      const voteAnswerFunctions = httpsCallable(functions, 'voteAnswer');

      try {
        // await voteAnswerFunctions({ gameId, selectedAnswerUid });
        await update(
          ref(
            realtimeDb,
            `games/${gameId}/rounds/${currentRound}/answers/${selectedAnswerUid}/votes`
          ),
          {
            [uid]: true,
          }
        );

        await runTransaction(
          ref(realtimeDb, `games/${gameId}/players/${uid}`),
          (currentData) => {}
        );

        await update(ref(realtimeDb, `games/${gameId}/players/${uid}`), {
          status: PLAYER_STATUSES.VOTED_ANSWER,
        });

        return true;
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  // Set game as ended
  const endGame = useCallback(
    async (gameId, { playersCount, onlinePlayersCount }) => {
      try {
        await update(ref(realtimeDb, `games/${gameId}/data`), {
          gameEnded: true,
        });

        logEvent(analytics, 'game_end', {
          playersCount,
          onlinePlayersCount,
        });

        return true;
      } catch (e) {
        console.log(e);
        // In case of an error display a notification
        displayNotification(ERRORS.DEFAULT, NOTIFICATION_SEVERITIES.ERROR);
        return false;
      }
    },
    [displayNotification]
  );

  const contextValue = {
    createNewGame,
    resetGame,
    joinGame,
    setNames,
    startGame,
    getLobbyQuestion,
    answerLobbyQuestion,
    inviteToGame,
    startGameTimer,
    nextRound,
    answerGameQuestion,
    voteAnswer,
    endGame,
  };

  return (
    <GameManagerContext.Provider value={contextValue}>
      {children}
    </GameManagerContext.Provider>
  );
}
