import { Grid, styled } from '@mui/material';
import { useMemo } from 'react';
import PropTypes from '../propTypes';

const TrackerContainer = styled(Grid, {
  shouldForwardProp: (prop) => !['width'].includes(prop),
})(({ theme, width }) => ({
  width: width ?? '100%',
  alignItems: 'flex-start',

  '.bar': {
    borderBottom: `1px solid`,
    borderColor: theme.palette.divider,
    flexGrow: 1,

    '&.active': {
      borderBottomWidth: '3px',
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function RoundProgressTracker({ activeIndex, bars, width }) {
  const array = useMemo(() => {
    const res = [];

    for (let i = 0; i < bars; i++) {
      res.push('');
    }

    return res;
  }, [bars]);

  return (
    <TrackerContainer container width={width}>
      {array.map((el, i) => (
        <Grid
          key={`round-progress-bar-${i}`}
          item
          className={`bar ${i === activeIndex ? 'active' : ''}`}
        />
      ))}
    </TrackerContainer>
  );
}

RoundProgressTracker.propTypes = {
  activeIndex: PropTypes.number,
  bars: PropTypes.number,
  width: PropTypes.any,
};
