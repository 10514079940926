import { Box, Button, Typography } from '@mui/material';
import RouterLink from '../../components/RouterLink';

export default function GameDoesNotExist() {
  return (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h2" mb={2}>
        404
      </Typography>

      <Typography gutterBottom mb={3}>
        No game was found
      </Typography>

      <Button variant="contained" fullWidth component={RouterLink} to="/">
        To Home
      </Button>
    </Box>
  );
}
