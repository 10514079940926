export const HEADER_WIDTH = 404;
export const PAGE_MAX_WIDTH = 375;

export const NOTIFICATION_SEVERITIES = {
	ERROR: 'error',
	SUCCESS: 'success',
	INFO: 'info',
};

export const ERRORS = {
	DEFAULT: 'There was a problem creating the request. Please, try again',
};

export const PLAYER_STATUSES = {
	NOT_READY: '0',
	SENT_ANSWER: '1',
	VOTED_ANSWER: '2',
};

export const GAME_PLAYERS_WAITING_ON_TIMEOUT_SECONDS = 30;
