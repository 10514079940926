import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import ScoreboardBlock from '../../components/ScoreboardBlock';
import { PAGE_MAX_WIDTH } from '../../constants';
import useAllPlayers from '../../hooks/game/useAllPlayers';
import useIsLastRound from '../../hooks/game/useIsLastRound';
import PropTypes from '../../propTypes';

const DrawerContainer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) => !['isLastRound'].includes(prop),
})(({ theme, isLastRound }) => ({
  '.MuiDrawer-paper': {
    minHeight: isLastRound ? `calc(100vh - 110px)` : '76vh',
    maxHeight: isLastRound ? `calc(100vh - 110px)` : '76vh',
    maxWidth: PAGE_MAX_WIDTH,
    left: '50%',
    top: 'initial',
    bottom: 0,
    marginLeft: -PAGE_MAX_WIDTH / 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTop: '4px solid',
    borderTopColor: theme.palette.primary.main,

    [theme.breakpoints.down(PAGE_MAX_WIDTH + 60)]: {
      left: 0,
      marginLeft: 0,
      maxWidth: '100%',
    },
  },

  '.scoreboard-header': {
    position: 'sticky',
    top: theme.spacing(-2),
    zIndex: 5,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(6, 0, 4),
  },

  '.close-button': {
    color: theme.palette.grey[800],

    '&:hover, &:active': {
      color: 'inherit',
    },
  },

  '.bottom-row': {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    zIndex: 2,
    marginTop: 'auto',
  },
}));

export default function ScoreboardDrawer({
  open,
  onOpen,
  onClose,
  onHighlightsOpen,
}) {
  const players = useAllPlayers();
  const isLastRound = useIsLastRound();

  const sortedPlayers = useMemo(() => {
    let res = players;

    res.sort((a, b) => {
      const scoreA = a.score ?? 0;
      const scoreB = b.score ?? 0;

      if (scoreA > scoreB) {
        return -1;
      }

      if (scoreA < scoreB) {
        return 1;
      }

      return 0;
    });

    return res;
  }, [players]);

  return (
    <DrawerContainer
      open={open}
      anchor="bottom"
      onOpen={onOpen}
      onClose={onClose}
      PaperProps={{ elevation: 0 }}
      isLastRound
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className="scoreboard-header"
      >
        <Typography variant="h2">scoreboard</Typography>
        <IconButton
          variant="outlined"
          onClick={onClose}
          className="close-button"
        >
          <Close />
        </IconButton>
      </Stack>

      <Stack spacing={1}>
        {sortedPlayers.map((player, i) => (
          <ScoreboardBlock
            key={`scoreboard-player-${player.uid}`}
            isFirst={i === 0}
            player={player}
          />
        ))}
      </Stack>

      {isLastRound && (
        <Box className="bottom-row">
          <Button fullWidth variant="outlined" onClick={onHighlightsOpen}>
            View Highlights
          </Button>
        </Box>
      )}
    </DrawerContainer>
  );
}

ScoreboardDrawer.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onHighlightsOpen: PropTypes.func,
};
