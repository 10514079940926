import { Grid, styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { animated } from 'react-spring';
import useButtonHighlight from '../hooks/useButtonHighlight';
import PropTypes from '../propTypes';
import PaperButton from '../styled-components/PaperButton';

const GridContainer = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
  paddingBottom: theme.spacing(2),
  flex: '0 1 auto',
}));

// Renders a grid with a list of players
export default function PlayersGrid({ players, onNameClick }) {
  const loading = useSelector((state) => state.game.loading);
  const onClick = (name) => () => onNameClick(name);

  const highlight = useButtonHighlight('lobby', 3, 1000);

  return (
    <GridContainer container spacing={1}>
      {players.map((name, i) => (
        <Grid item key={`${name}-${i}`} xs={6} component={animated.div}>
          <PaperButton
            onClick={onClick(name)}
            loading={loading}
            highlight={i === 0 ? highlight : false}
          >
            <Typography className="name">{name}</Typography>
          </PaperButton>
        </Grid>
      ))}
    </GridContainer>
  );
}

PlayersGrid.propTypes = {
  players: PropTypes.array,
  onNameClick: PropTypes.func,
};
