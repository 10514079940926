import { Avatar, Box, styled, Typography } from '@mui/material';
import { PLAYER_STATUSES } from '../constants';
import PropTypes from '../propTypes';
import getValueByKeys from '../utils/getValueByKeys';

const Container = styled('div', {
  shouldForwardProp: (prop) => !['small', 'showStatus'].includes(prop),
})(({ theme, small, status }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 150,

  '.avatar-container': {
    width: theme.spacing(small ? 6 : 8),
    height: theme.spacing(small ? 8 : 10),
    marginBottom: theme.spacing(0.6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '.avatar': {
      height: 'auto',
      width: '220%',
    },
  },

  '.name-container': {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: getValueByKeys(
      status,
      {
        [PLAYER_STATUSES.NOT_READY]: theme.palette.error.main,
        // [PLAYER_STATUSES.READY]: theme.palette.te,
      },
      'inherit'
    ),
  },

  '.name': {
    fontSize: '0.875rem',
    fontWeight: 800,
  },
}));

export default function PlayerProfile({
  src,
  name,
  small = false,
  status = null,
}) {
  return (
    <Container small={small} status={status}>
      <Box className="avatar-container">
        <Avatar src={src} className="avatar" />
      </Box>
      <Box className="name-container">
        <Typography className="name">{name}</Typography>
      </Box>
    </Container>
  );
}

PlayerProfile.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  small: PropTypes.bool,
};
