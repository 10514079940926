import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageBottomRow from '../../components/PageBottomRow';
import SummaryBlock from '../../components/SummaryBlock';
import useAllPlayers from '../../hooks/game/useAllPlayers';
import useIsLastRound from '../../hooks/game/useIsLastRound';
import useGameManagerContext from '../../hooks/useGameManagerContext';
import {
  setLoading,
  useCurrentRound,
  useGameData,
  useGameId,
  useIsHost,
  useLoading,
  useOnlinePlayers,
} from '../../store/gameSlice';
import Highlights from './Highlights';
import ScoreboardDrawer from './ScoreboardDrawer';

const RoundResultsContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',

  '.results-header': {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.default,
    paddingBottom: theme.spacing(4),
    zIndex: 5,
  },
}));

export default function RoundResults() {
  const loading = useLoading();
  const isHost = useIsHost();
  const gameData = useGameData();
  const gameId = useGameId();
  const currentRound = useCurrentRound();
  const isLastRound = useIsLastRound();
  const players = useAllPlayers();
  const onlinePlayers = useOnlinePlayers();

  const [scoreboardOpen, setScoreboardOpen] = useState(false);
  const [highlightsOpen, setHighlightsOpen] = useState(false);
  const [isGameEndedSet, setIsGameEndedSet] = useState(false);
  const dispatch = useDispatch();
  const { nextRound, resetGame, endGame } = useGameManagerContext();

  const answers = useMemo(() => {
    if (!currentRound) {
      return [];
    }

    const res = Object.entries(currentRound.answers);

    res.sort(([, a], [, b]) => {
      const votesA = a.votes ? Object.keys(a.votes).length : 0;
      const votesB = b.votes ? Object.keys(b.votes).length : 0;

      if (votesA > votesB) {
        return -1;
      }

      if (votesA < votesB) {
        return 1;
      }

      return 0;
    });

    return res;
  }, [currentRound]);

  const onNextRoundClick = async () => {
    if (loading) {
      return;
    }

    dispatch(setLoading(true));

    const res = await nextRound(gameId, true);

    dispatch(setLoading(false));
  };

  const onNewGameClick = async () => {
    if (loading) {
      return;
    }

    dispatch(setLoading(true));

    const res = await resetGame(gameId, true);

    dispatch(setLoading(false));
  };

  const onScoreboardOpen = () => {
    setScoreboardOpen(true);
  };

  const onScoreboardClose = () => {
    setScoreboardOpen(false);
  };

  const onHighlightsOpen = () => {
    setHighlightsOpen(true);
  };

  const onHighlightsClose = () => {
    setHighlightsOpen(false);
  };

  useEffect(() => {
    const sendGameEnded = async () => {
      await endGame(gameId, {
        playersCount: players.length,
        onlinePlayersCount: onlinePlayers.length,
      });
    };

    if (!isLastRound || !isHost) {
      return;
    }

    if (gameData.gameEnded || isGameEndedSet) {
      return;
    }

    sendGameEnded();
    setIsGameEndedSet(true);
  }, [gameData, isLastRound, isHost]);

  return (
    <RoundResultsContainer>
      <ScoreboardDrawer
        open={scoreboardOpen && !highlightsOpen}
        onOpen={onScoreboardOpen}
        onClose={onScoreboardClose}
        onHighlightsOpen={onHighlightsOpen}
      />
      {isLastRound && (
        <Highlights
          open={highlightsOpen}
          onOpen={onHighlightsOpen}
          onClose={onHighlightsClose}
        />
      )}

      <Typography variant="h6" mb={2}>
        {isLastRound ? (
          <>
            LAST ROUND{' '}
            <Typography variant="h6" component="span" color="primary">
              (DOUBLE POINTS)
            </Typography>
          </>
        ) : (
          <>
            Round{' '}
            <Typography variant="h6" component="span" color="primary">
              {parseInt(gameData.currentRound) + 1}/{gameData.maxRounds}
            </Typography>
          </>
        )}
      </Typography>

      <Box className="results-header">
        <Typography variant="h1">{currentRound.questionText}</Typography>
      </Box>
      <Stack spacing={2} className="blocks-container">
        {answers.map(([uid, { text, votes }]) => (
          <SummaryBlock
            key={`summary-${uid}`}
            text={text}
            uid={uid}
            votes={votes}
            isLastRound={isLastRound}
          />
        ))}
      </Stack>
      <PageBottomRow>
        <Grid container spacing={2}>
          <Grid item xs={isHost ? 6 : 12}>
            <Button fullWidth variant="outlined" onClick={onScoreboardOpen}>
              {isLastRound ? 'Results' : 'Scoreboard'}
            </Button>
          </Grid>
          {isHost && (
            <Grid item xs={6}>
              {isLastRound ? (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={onNewGameClick}
                  loading={loading}
                >
                  New Game
                </LoadingButton>
              ) : (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={onNextRoundClick}
                  loading={loading}
                >
                  Next Round
                </LoadingButton>
              )}
            </Grid>
          )}
        </Grid>
      </PageBottomRow>
    </RoundResultsContainer>
  );
}
