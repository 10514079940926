import { useEffect, useRef, useState } from 'react';

export default function useButtonHighlight(localStorageKey, times, delay = 0) {
	const [highlight, setHighlight] = useState(false);
	const [startInterval, setStartInterval] = useState(false);
	const [count, setCount] = useState(0);
	const intervalRef = useRef();

	useEffect(() => {
		// Timeout used to create delay effect
		let timeout = null;

		// Value from the local storage
		const highlightLocalStorage = JSON.parse(
			localStorage.getItem(localStorageKey)
		);

		// If the value ahs not been yet set, then start the interval after the delay
		if (!highlightLocalStorage) {
			timeout = setTimeout(() => {
				setStartInterval(true);
			}, delay);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		if (!startInterval) {
			return;
		}

		// Interval that toggles the highlight
		intervalRef.current = setInterval(() => {
			// If count has reached max times
			if (count === times) {
				// Clear the interval
				clearInterval(intervalRef.current);
				setHighlight(false);

				// Set the local storage item to true
				localStorage.setItem(localStorageKey, JSON.stringify(true));
				return;
			}

			// Otherwise increase the count and toggle the highlight
			setCount((prev) => prev + 1);
			setHighlight((prev) => !prev);
		}, 1000);

		return () => {
			clearTimeout(intervalRef.current);
		};
	}, [startInterval, count]);

	return highlight;
}
