import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PageBottomRow from '../../components/PageBottomRow';
import PlayersRow from '../../components/PlayersRow';
import RoundProgressTracker from '../../components/RoundProgressTracker';
import { PLAYER_STATUSES } from '../../constants';
import { VALIDATORS } from '../../constants/validators';
import useIsLastRound from '../../hooks/game/useIsLastRound';
import useGameManagerContext from '../../hooks/useGameManagerContext';
import usePlayersWaitingOn from '../../hooks/usePlayersWaitingOn';
import { useUid } from '../../store/authSlice';
import {
  setLoading,
  useCurrentRound,
  useGameData,
  useGameId,
  useLoading,
  usePlayerData,
} from '../../store/gameSlice';

const GameQuestionContainer = styled('div')(({ theme }) => ({
  textAlign: 'center',
}));

export default function RoundQuestion() {
  const dispatch = useDispatch();
  const uid = useUid();
  const [isEditingAnswer, setIsEditingAnswer] = useState(false);
  const [answerSent, setAnswerSent] = useState(false);

  const loading = useLoading();
  const gameData = useGameData();
  const currentRound = useCurrentRound();
  const gameId = useGameId();
  const playerData = usePlayerData();
  const isLastRound = useIsLastRound();

  const { answerGameQuestion } = useGameManagerContext();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      answer: currentRound?.answers
        ? currentRound?.answers[uid]?.text ?? ''
        : '',
    },
    shouldUnregister: false,
  });

  const { displayPlayers, waitingOnPlayers } = usePlayersWaitingOn();

  const isReady =
    !isEditingAnswer &&
    (playerData.status === PLAYER_STATUSES.SENT_ANSWER || answerSent);

  const onFormSubmit = async (data) => {
    if (loading) {
      return;
    }

    dispatch(setLoading(true));

    const res = await answerGameQuestion({
      gameId,
      currentRound: gameData.currentRound,
      answer: data.answer.trim(),
      uid,
    });

    setIsEditingAnswer(false);
    dispatch(setLoading(false));
    setAnswerSent(true);
  };

  const onEditButtonClick = () => {
    setIsEditingAnswer(true);
  };

  const onCancelEditButtonClick = () => {
    setIsEditingAnswer(false);
  };

  return (
    <GameQuestionContainer as="form" onSubmit={handleSubmit(onFormSubmit)}>
      <Typography variant="h6" mb={2}>
        {isLastRound ? (
          <>
            LAST ROUND{' '}
            <Typography variant="h6" component="span" color="primary">
              (DOUBLE POINTS)
            </Typography>
          </>
        ) : (
          <>
            Round{' '}
            <Typography variant="h6" component="span" color="primary">
              {parseInt(gameData.currentRound) + 1}/{gameData.maxRounds}
            </Typography>
          </>
        )}
      </Typography>

      <Typography variant="h1" mb={4}>
        {currentRound?.questionText}
      </Typography>

      <Box display="flex" justifyContent="center" mb={4}>
        <RoundProgressTracker activeIndex={0} bars={2} width="55%" />
      </Box>

      {isReady && (
        <Box mb={4}>
          <Typography variant="h3" color="primary" mb={2}>
            Your answer:
          </Typography>

          <Paper sx={{ p: 2 }}>
            <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
              {currentRound?.answers[uid]?.text}
            </Typography>
          </Paper>
        </Box>
      )}

      {displayPlayers && (
        <>
          <Typography variant="h3" mb={2}>
            Waiting on:
          </Typography>

          <PlayersRow
            centered
            players={waitingOnPlayers}
            showStatus
            sx={{ mb: 4 }}
          />
        </>
      )}

      {!isReady && (
        <FormControl fullWidth error={Boolean(errors.answer)}>
          <Input
            placeholder="Type your answer"
            autoFocus
            inputProps={{
              ...register('answer', VALIDATORS.ANSWER),
            }}
            autoComplete="off"
            disabled={loading}
          />
          {errors?.answer && (
            <FormHelperText sx={{ textAlign: 'center' }}>
              {errors.answer.message}
            </FormHelperText>
          )}
        </FormControl>
      )}

      <PageBottomRow>
        {isReady ? (
          <Button
            type="button"
            variant="contained"
            disabled={!isValid}
            fullWidth
            onClick={onEditButtonClick}
          >
            Edit Answer
          </Button>
        ) : (
          <Grid container spacing={2}>
            {isEditingAnswer && (
              <Grid item xs={6}>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={!isValid}
                  fullWidth
                  onClick={onCancelEditButtonClick}
                  color="inherit"
                >
                  Cancel
                </Button>
              </Grid>
            )}
            <Grid item xs={isEditingAnswer ? 6 : 12}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!isValid}
                fullWidth
                loading={loading}
              >
                Send
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </PageBottomRow>
    </GameQuestionContainer>
  );
}
