import { useMemo } from 'react';
import { useTimer } from 'react-timer-hook';
import { GAME_PLAYERS_WAITING_ON_TIMEOUT_SECONDS } from '../constants';
import { PLAYER_STATUSES } from '../constants/index';
import { useCurrentRound, useOnlinePlayers } from '../store/gameSlice';

// Returns an object with an array of players that are not ready
// and also a statee variable that controls whether to show the players row
export default function usePlayersWaitingOn() {
  const currentRound = useCurrentRound();
  const onlinePlayers = useOnlinePlayers();

  // The time that the round has been created at
  const roundCreatedAt = new Date(currentRound.createdAt);

  // Use timer to display the players wating on only after 30 seconds
  const { seconds, isRunning } = useTimer({
    expiryTimestamp: new Date(
      roundCreatedAt.getFullYear(),
      roundCreatedAt.getMonth(),
      roundCreatedAt.getDate(),
      roundCreatedAt.getHours(),
      roundCreatedAt.getMinutes(),
      roundCreatedAt.getSeconds() + 30
    ),
    autoStart: true,
  });

  // Display players only if at least half of the players have answered / voted
  // and 30 seconds have passed since the beginning of the round
  let displayPlayers = false;

  if (currentRound) {
    const playersHalf = Math.floor(onlinePlayers.length / 2);

    if (currentRound.collectingAnswers) {
      displayPlayers = currentRound.answers
        ? Object.keys(currentRound.answers).length >= playersHalf
        : false;
    } else {
      displayPlayers = currentRound.votes >= playersHalf;
    }

    displayPlayers =
      displayPlayers &&
      (isRunning ? seconds > GAME_PLAYERS_WAITING_ON_TIMEOUT_SECONDS : true);
  }

  // The array of players that are not ready
  const waitingOnPlayers = useMemo(() => {
    return onlinePlayers.filter(
      (player) =>
        player.status !==
        (currentRound.collectingAnswers
          ? PLAYER_STATUSES.SENT_ANSWER
          : PLAYER_STATUSES.VOTED_ANSWER)
    );
  }, [onlinePlayers]);

  return { waitingOnPlayers, displayPlayers };
}
