export const firebaseConfig = {
	apiKey: 'AIzaSyDxSKoNe6AuGvsM6TEQmwlURlSomDYQM6I',
	authDomain: 'ucked-ebfc6.firebaseapp.com',
	databaseURL: 'https://ucked-ebfc6-default-rtdb.firebaseio.com',
	projectId: 'ucked-ebfc6',
	storageBucket: 'ucked-ebfc6.appspot.com',
	messagingSenderId: '547180514166',
	appId: '1:547180514166:web:e38f66f869991b17868857',
	measurementId: 'G-CB7TK38385',
};
