import { styled, Typography } from '@mui/material';
import { ref } from 'firebase/database';
import { useMemo } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useSelector } from 'react-redux';
import useAllPlayers from '../../hooks/game/useAllPlayers';
import useCountdownTimer from '../../hooks/useCountdownTimer';
import useGameManagerContext from '../../hooks/useGameManagerContext';
import {
  useGameData,
  useGameId,
  useOnlinePlayers,
} from '../../store/gameSlice';
import { realtimeDb } from '../../utils/firebase';
import PageLoader from '../PageLoader';

const TimerContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  textAlign: 'center',
  flex: '1 1 auto',
}));

export default function CountdownTimer() {
  const gameData = useGameData();
  const gameId = useGameId();
  const onlinePlayers = useOnlinePlayers();
  const players = useAllPlayers();
  const { startGame } = useGameManagerContext();

  const isHost = useSelector((state) => state.game.isHost);

  const [serverTimeOffset] = useObjectVal(
    ref(realtimeDb, '.info/serverTimeOffset')
  );

  const endAt = useMemo(() => {
    if (!gameData.timer) {
      return 0;
    }

    return gameData.timer.startedAt + gameData.timer.from * 1000;
  }, []);

  const seconds = useCountdownTimer(
    gameData.timer.started,
    gameData.timer.from,
    endAt,
    serverTimeOffset ?? 0,
    () =>
      isHost &&
      startGame(gameId, {
        namesCount: gameData.names.length,
        playersCount: players.length,
        onlinePlayersCount: onlinePlayers.length,
      })
  );

  return (
    <TimerContainer>
      {seconds > 0 ? (
        <>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Game starts in...
          </Typography>
          <Typography variant="h3">{seconds}</Typography>
        </>
      ) : (
        <PageLoader />
      )}
    </TimerContainer>
  );
}
