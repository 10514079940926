import { ref } from 'firebase/database';
import { useEffect } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useDispatch } from 'react-redux';
import { setLoadingState, setQuestionsStats } from '../../../store/gameSlice';
import { realtimeDb } from '../../../utils/firebase';

export default function useQuestionsStatsController() {
  const dispatch = useDispatch();

  const [questionsStats, questionStatsLoading] = useObjectVal(
    ref(realtimeDb, 'stats/questions')
  );

  useEffect(() => {
    dispatch(setQuestionsStats(questionsStats ?? null));
  }, [questionsStats]);

  useEffect(() => {
    dispatch(
      setLoadingState({
        key: 'questionStats',
        value: questionStatsLoading,
      })
    );
  }, [questionStatsLoading]);
}
