import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { NOTIFICATION_SEVERITIES } from '../constants';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    message: '',
    severity: NOTIFICATION_SEVERITIES.INFO,
    isOpen: false,
  },
  reducers: {
    displayNotification(state, action) {
      const { message, severity = NOTIFICATION_SEVERITIES.INFO } =
        action.payload;

      state.message = message;
      state.severity = severity;
      state.isOpen = true;
    },
    closeNotification(state) {
      state.isOpen = false;
    },
  },
});

export const { displayNotification, closeNotification } =
  notificationSlice.actions;

const notificationReducer = notificationSlice.reducer;

export const useNotificationMessage = () =>
  useSelector((state) => state.notification.message);
export const useNotificationSeverity = () =>
  useSelector((state) => state.notification.severity);
export const useNotificationIsOpen = () =>
  useSelector((state) => state.notification.isOpen);

export default notificationReducer;
