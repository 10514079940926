import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import AnimatedHeader from '../components/AnimatedHeader';
import PageLoader from '../components/PageLoader';
import { HEADER_WIDTH, PAGE_MAX_WIDTH } from '../constants';
import { GameManagerContextProvider } from '../contexts/GameManagerContext';
import useAuthStateController from '../hooks/game/controllers/useAuthStateController';
import { useUid } from '../store/authSlice';

const LayoutContainer = styled('div')(({ theme }) => ({
  maxWidth: PAGE_MAX_WIDTH,
  margin: 'auto',

  '.page': {
    overflowX: 'hidden',
    overflowY: 'auto',
    maxWidth: '100%',
    marginTop: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [theme.breakpoints.down(HEADER_WIDTH + 32)]: {
    overflow: 'hidden',
    maxWidth: '100%',
  },
}));

export default function MainLayout() {
  const uid = useUid();
  const pagePaddingBottom = useSelector((state) => state.app.pagePaddingBottom);

  useAuthStateController();

  return (
    <GameManagerContextProvider>
      <LayoutContainer>
        <AnimatedHeader />
        {!uid ? (
          <PageLoader />
        ) : (
          <div
            className="page"
            style={{
              paddingBottom: `${pagePaddingBottom}px`,
              // height: `calc(100vh - 108px - 27px - ${pagePaddingBottom}px)`,
              // maxHeight: `calc(100vh - 108px - 27px - ${pagePaddingBottom}px)`,
              height: `calc(100vh - 108px - 27px)`,
              maxHeight: `calc(100vh - 108px - 27px)`,
            }}
          >
            <Outlet />
          </div>
        )}
      </LayoutContainer>
    </GameManagerContextProvider>
  );
}
