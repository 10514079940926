import { QuestionMark } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from '../propTypes';
import getRandomInt from '../utils/getRandomInt';
import TutorialDrawer from '../views/TutorialDrawer';

const SelectContainer = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  position: 'relative',
  width: '100%',
  margin: 'auto',
  minHeight: 225,
  marginBottom: theme.spacing(4),

  '.character-image': {
    maxWidth: '100%',
    transform: 'scale(1.1)',
  },

  '.click-container': {
    position: 'absolute',
    top: 0,
    width: 170,
    left: '50%',
    marginLeft: -85,
    height: '100%',
    zIndex: 2,
  },

  '.tutorial-button': {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
  },
}));

export default function CharacterSelect({
  colorModeDark,
  setColorModeDark,
  index,
  setIndex,
}) {
  const [isTutorialDrawerOpen, setIsTutorialDrawerOpen] = useState(
    localStorage.getItem('tutorialDrawer') !== 'true'
  );

  // Controls if the user has pressed on the character, i.e changing the color mode
  const [animated, setAnimated] = useState(false);

  // On character change
  const onCharacterRefreshClick = () => {
    // Get new index of the character
    let newIndex = getRandomInt(0, 10);

    while (newIndex === index) {
      newIndex = getRandomInt(0, 10);
    }

    setIndex(newIndex);
    setAnimated(false);
  };

  // On character color mode change (dark -> light or light -> dark)
  const onColorModeChange = () => {
    if (!animated) {
      setAnimated(true);
    }

    setColorModeDark(!colorModeDark);
  };

  useEffect(() => {
    // Preload character images to remove flicker on character change

    // Light characters
    for (let i = 0; i < 11; i++) {
      const img = new Image();
      img.src = `/images/characters/light/${i}.gif`;
    }

    // Dark characters
    for (let i = 0; i < 11; i++) {
      const img = new Image();
      img.src = `/images/characters/dark/${i}.gif`;
    }

    // Dark to light animated characters
    for (let i = 0; i < 11; i++) {
      const img = new Image();
      img.src = `/images/characters/dark-to-light/${i}.gif`;
    }

    // Light to dark animated characters
    for (let i = 0; i < 11; i++) {
      const img = new Image();
      img.src = `/images/characters/light-to-dark/${i}.gif`;
    }
  }, []);

  const onTutorialDrawerClose = () => {
    setIsTutorialDrawerOpen(false);
    localStorage.setItem('tutorialDrawer', 'true');
  };

  return (
    <SelectContainer>
      <TutorialDrawer
        open={isTutorialDrawerOpen}
        onClose={onTutorialDrawerClose}
        onOpen={() => setIsTutorialDrawerOpen(true)}
      />
      <Box className="click-container" onClick={onColorModeChange} />
      <img
        src={`/images/characters/${
          animated
            ? colorModeDark
              ? 'light-to-dark'
              : 'dark-to-light'
            : colorModeDark
            ? 'dark'
            : 'light'
        }/${index}.gif`}
        alt="character"
        className="character-image"
      />

      <IconButton
        color="primary"
        className="tutorial-button"
        size="large"
        type="button"
        variant="outlined"
        onClick={() => setIsTutorialDrawerOpen(true)}
      >
        <QuestionMark />
      </IconButton>
    </SelectContainer>
  );
}

CharacterSelect.propTypes = {
  colorModeDark: PropTypes.bool.isRequired,
  setColorModeDark: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
};
