import { useMemo } from 'react';
import useAllPlayers from './game/useAllPlayers';

export default function usePlayerDataByUid(uid) {
  const allPlayers = useAllPlayers();

  const playerData = useMemo(() => {
    let res = null;

    for (const player of allPlayers) {
      if (player.uid === uid) {
        res = player;
        break;
      }
    }

    return res;
  }, [uid, allPlayers]);

  return playerData;
}
