import { Avatar, Box, Grid, Paper, styled, Typography } from '@mui/material';
import PropTypes from '../propTypes';
import getCharacterImage from '../utils/getCharacterImage';

const BlockContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isFirst',
})(({ theme, isFirst }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  textAlign: isFirst ? 'center' : 'left',

  '.number-one-label': {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.grey[800],
  },

  '.avatar-container': {
    height: 'auto',
    minWidth: isFirst ? '100%' : theme.spacing(10),
    maxWidth: isFirst ? '100%' : theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '.MuiAvatar-root': {
      height: 'auto',
      width: isFirst ? 200 : 140,
    },
  },

  '.MuiTypography-root': {
    maxWidth: '100%',
  },
}));

export default function ScoreboardBlock({ isFirst = false, player }) {
  return (
    <BlockContainer elevation={0} isFirst={isFirst}>
      {isFirst && (
        <Box className="number-one-label">
          <Typography variant="h3">#1</Typography>
        </Box>
      )}
      <Grid
        container
        spacing={2}
        alignItems={isFirst ? 'stretch' : 'center'}
        justifyContent={isFirst ? 'stretch' : 'space-between'}
      >
        <Grid
          item
          container
          xs={isFirst ? 12 : 'auto'}
          alignItems={isFirst ? 'stretch' : 'center'}
          spacing={isFirst ? 0 : 2}
          flexWrap={isFirst ? 'wrap' : 'nowrap'}
          maxWidth="100%"
        >
          <Grid item className="avatar-container" xs={isFirst ? 12 : 'auto'}>
            <Avatar
              src={getCharacterImage(player.characterMode, player.characterId)}
              alt="character"
            />
          </Grid>

          <Grid item xs={isFirst ? 12 : 'auto'} flexShrink={1}>
            <Typography variant={isFirst ? 'h1' : 'h3'}>
              {player.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={isFirst ? 12 : 'auto'} flexGrow={1}>
          <Typography
            variant="h3"
            color="primary"
            textAlign={isFirst ? 'inherit' : 'right'}
          >
            {player.score ?? 0} {player.score !== 1 ? 'points' : 'point'}
          </Typography>
        </Grid>
      </Grid>
    </BlockContainer>
  );
}

ScoreboardBlock.propTypes = {
  isFirst: PropTypes.bool,
  player: PropTypes.any.isRequired,
};
