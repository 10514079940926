import { GlobalStyles } from '@mui/material';

// const GlobalStyles = createGlobalStyle`
//     body {
//         margin: 0;
//         font-family: 'Cabinet Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//             sans-serif;
//         -webkit-font-smoothing: antialiased;
//         -moz-osx-font-smoothing: grayscale;
//         background-color: ${(props) => props.theme.palette.black};
//         color: ${(props) => props.theme.palette.lightGrey}
//     }

// `;

export default function AppStyles() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        body: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      })}
    />
  );
}
