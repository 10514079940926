import { styled } from '@mui/material';
import { useState } from 'react';
import {
  animated,
  easings,
  useChain,
  useSpring,
  useSpringRef,
} from 'react-spring';
import { HEADER_WIDTH, PAGE_MAX_WIDTH } from '../constants';

const HeaderContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingTop: theme.spacing(3),
  zIndex: 1400,

  '.logo-container': {
    position: 'relative',
    zIndex: 11,
    textAlign: 'center',

    img: {
      position: 'relative',
      width: HEADER_WIDTH,
      left: -(HEADER_WIDTH - PAGE_MAX_WIDTH) / 2,

      [theme.breakpoints.down(HEADER_WIDTH + 32)]: {
        width: `calc(100% + 29px)`,
        left: -14.5,
      },
    },

    '.subtitle': {
      maxWidth: '60%',
      margin: 'auto',
      marginTop: '2rem',
    },
  },

  '.subtitle-container': {
    position: 'relative',
    zIndex: 11,
  },

  '.overlay': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 10,
  },
}));

export default function AnimatedHeader() {
  const [expanded, setExpanded] = useState(true);

  const logoSpringRef = useSpringRef();
  const logoProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1500 },
    ref: logoSpringRef,
  });

  const logoContainerRef = useSpringRef();
  const logoContainerProps = useSpring({
    from: { y: 300 },
    // from: { y: 0 },
    to: { y: 0 },
    config: {
      duration: 500,
      easing: easings.easeInOutCubic,
    },
    ref: logoContainerRef,
  });

  const overlayProps = useSpring({
    from: { opacity: 1 },
    // from: { opacity: 0 },
    to: { opacity: 0 },
    config: {
      duration: 700,
      easing: easings.easeInOutCubic,
    },
    onResolve: () => setExpanded(false),
    delay: 1500,
  });

  useChain([logoSpringRef, logoContainerRef]);

  return (
    <HeaderContainer>
      <animated.div style={logoContainerProps} className="logo-container">
        <animated.img
          style={logoProps}
          src="/images/UCKED_logo_main_light_grey.svg"
          alt="logo"
        />
      </animated.div>

      {expanded && (
        <animated.div className="overlay" style={overlayProps}></animated.div>
      )}
    </HeaderContainer>
  );
}
