import { Box, Divider, styled, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { animated } from 'react-spring';
import usePlayerDataByUid from '../../../hooks/usePlayerData';
import getCharacterImage from '../../../utils/getCharacterImage';

const CardContainer = styled(Box)(({ theme }) => ({
  maxHeight: 220,
  minHeight: 220,
  height: 'fit-content',
  width: '100%',
  position: 'absolute',
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  color: theme.palette.common.black,
  overflow: 'hidden',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '.content': {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '.image-container': {
    maxWidth: 50,
    maxHeight: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),

    img: {
      width: '300%',
    },
  },

  '.player-name': {
    marginBottom: theme.spacing(1.5),
  },

  '.answer': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    lineClamp: 4,
    WebkitBoxOrient: 'vertical',
  },
}));

const HighlightCard = forwardRef(
  ({ uid, text, style: { contentOpacity, ...style }, ...props }, ref) => {
    const playerData = usePlayerDataByUid(uid);

    return (
      <CardContainer
        ref={ref}
        component={animated.div}
        style={style}
        {...props}
      >
        <Box
          component={animated.div}
          className="content"
          style={{ opacity: contentOpacity }}
        >
          <Box className="image-container">
            <img
              src={getCharacterImage(
                playerData?.characterMode,
                playerData?.characterId
              )}
              alt="character"
            />
          </Box>
          <Typography variant="h3" className="player-name">
            {playerData?.name}
          </Typography>
          <Divider sx={{ width: '100%', mb: 1.5 }} />
          <Typography variant="h4" className="answer">
            {text}
          </Typography>
        </Box>
      </CardContainer>
    );
  }
);

export default HighlightCard;
