import { useMemo } from 'react';
import { useOfflinePlayers, useOnlinePlayers } from '../../store/gameSlice';

export default function useAllPlayers() {
  const onlinePlayers = useOnlinePlayers();
  const offlinePlayers = useOfflinePlayers();

  const allPlayers = useMemo(() => {
    return [...onlinePlayers, ...offlinePlayers];
  }, [onlinePlayers, offlinePlayers]);

  return allPlayers;
}
