import { Send } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ref, set } from 'firebase/database';
import { useState } from 'react';
import { useListVals } from 'react-firebase-hooks/database';
import { realtimeDb } from '../utils/firebase';

const QuestionsManagerContainer = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',

  '.MuiDataGrid-root': {
    flex: '1 1 auto',
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
  },

  '.MuiInputBase-root': {
    borderRadius: '5px',
  },
}));

export default function QuestionsManager() {
  const [type, setType] = useState('personal');
  const isGeneral = type === 'general';
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const [questions, questionLoading] = useListVals(
    ref(realtimeDb, `questions/${type}`),
    { keyField: 'id' }
  );
  console.log(questions);

  let additionalColumn = [];

  if (!isGeneral) {
    additionalColumn = [
      {
        field: 'questions',
        headerName: 'Question',
        flex: 1,
        editable: true,
        renderCell: (params) => params.value.join(', '),
      },
    ];
  }

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 80,
    },
    {
      field: isGeneral ? 'question' : 'lobby',
      headerName: 'Question',
      maxWidth: 300,
      flex: 1,
      editable: true,
    },
    ...additionalColumn,
  ];

  const onAddQuestionsClick = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    const questions = value.trim().split('\n');
    let res = [];

    if (isGeneral) {
      for (const question of questions) {
        res.push({ question });
      }
    } else {
      for (const question of questions) {
        const [lobby, ...questions] = question.split('\t');
        res.push({ lobby, questions });
      }
    }

    await set(ref(realtimeDb, `questions/${type}`), res);
    await set(
      ref(
        realtimeDb,
        `stats/questions/${
          isGeneral ? 'totalGeneralQuestions' : 'totalPersonalQuestions'
        }`
      ),
      res.length
    );
    setLoading(false);
    setValue('');
  };

  return (
    <QuestionsManagerContainer>
      <FormControl sx={{ mb: 2 }}>
        <InputLabel id="question-type-label">Question type</InputLabel>
        <Select
          labelId="question-type-label"
          id="question-type"
          value={type}
          label="Question type"
          onChange={({ target: { value } }) => setType(value)}
        >
          <MenuItem value="personal">Personal</MenuItem>
          <MenuItem value="general">General</MenuItem>
        </Select>
      </FormControl>
      <DataGrid columns={[...columns]} rows={questions} />

      <Box component="form" onSubmit={onAddQuestionsClick}>
        <TextField
          fullWidth
          label="New questions"
          multiline
          value={value}
          onChange={({ target: { value } }) => setValue(value)}
          InputProps={{
            endAdornment: (
              <IconButton size="large" onClick={onAddQuestionsClick}>
                <Send />
              </IconButton>
            ),
          }}
          rows={6}
        />
      </Box>
    </QuestionsManagerContainer>
  );
}
