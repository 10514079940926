import { useEffect, useRef, useState } from 'react';

// Hook that starts a countdownTimer and returns number of seconds that left until the end timestamp
export default function useCountdownTimer(
	timerStarted,
	initialSeconds,
	endAtMillis,
	serverOffsetMillis,
	onCountdownEnd
) {
	const [seconds, setSeconds] = useState(initialSeconds);
	const intervalRef = useRef(null);

	useEffect(() => {
		if (!timerStarted) {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			return;
		}

		intervalRef.current = setInterval(() => {
			const now = new Date();
			const secondsLeft = Math.floor(
				(endAtMillis - now.getTime() - serverOffsetMillis) / 1000
			);
			console.log(secondsLeft);

			if (secondsLeft <= 0) {
				clearInterval(intervalRef.current);

				if (typeof onCountdownEnd === 'function') {
					onCountdownEnd();
				}
			}

			setSeconds(secondsLeft);
		}, 100);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, [timerStarted]);

	return seconds;
}
