import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { firebaseConfig } from '../firebaseConfig';

initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
export const functions = getFunctions();
export const realtimeDb = getDatabase();
export const analytics = getAnalytics();

const host = window.location.hostname;
if (host === 'localhost') {
  // connectDatabaseEmulator(realtimeDb, host, 9000);
  // connectFunctionsEmulator(functions, host, 5001);
  // connectFirestoreEmulator(db, host, 8080);
}
