import { Container, styled, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

const AdminLayoutContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(4),
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',

  '.page-container': {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function AdminLayout() {
  return (
    <AdminLayoutContainer>
      <Container className="page-container">
        <Typography variant="h1" mb={4}>
          Admin Panel
        </Typography>
        <Outlet />
      </Container>
    </AdminLayoutContainer>
  );
}
