import useCurrentRoundController from '../../hooks/game/controllers/useCurrentRoundController';
import useGameDataController from '../../hooks/game/controllers/useGameDataController';
import usePlayerOnlineStatusController from '../../hooks/game/controllers/usePlayerOnlineStatusControler';
import usePlayersController from '../../hooks/game/controllers/usePlayersController';
import useQuestionsStatsController from '../../hooks/game/controllers/useQuestionsStatsController';
import {
  useCurrentRound,
  useDoesGameExist,
  useGameData,
  useIsInitialized,
  useLoading,
  usePlayerData,
} from '../../store/gameSlice';
import GameDoesNotExist from '../../views/Game/GameDoesNotExist';
import RoundQuestion from '../../views/Game/RoundQuestion';
import RoundResults from '../../views/Game/RoundResults';
import RoundVote from '../../views/Game/RoundVote';
import Lobby from '../../views/Lobby';
import SelectNameAndCharacter from '../../views/SelectNameAndCharacter';
import CountdownTimer from '../CountdownTimer';
import PageLoader from '../PageLoader';

export default function GameController() {
  const isInitialized = useIsInitialized();
  const doesGameExist = useDoesGameExist();
  const playerData = usePlayerData();
  const loading = useLoading();
  const currentRoundLoading = useLoading('currentRound');
  const gameData = useGameData();
  const currentRound = useCurrentRound();

  // Questions stats
  useQuestionsStatsController();

  // Game Data
  useGameDataController();

  // Players
  usePlayersController();

  // Current Round
  useCurrentRoundController();

  // Player Online Status
  usePlayerOnlineStatusController();

  if (!isInitialized || currentRoundLoading || loading) {
    return <PageLoader />;
  }

  if (!doesGameExist) {
    // Game does not exist
    return <GameDoesNotExist />;
  }

  // Game exists
  if (!playerData?.online) {
    // Player is offline

    // Join the games
    return <SelectNameAndCharacter isHost={false} />;
  }

  if (!gameData.gameStarted) {
    // Lobby

    if (gameData.timer?.started) {
      // Countdown timer
      return <CountdownTimer />;
    }

    return <Lobby />;
  }

  // Game started
  if (!currentRound) {
    // Round loading
    return <PageLoader />;
  }

  if (currentRound.roundEnded) {
    // Round results
    return <RoundResults />;
  }

  if (currentRound.collectingAnswers) {
    // Round question
    return <RoundQuestion />;
  }

  // Round vote
  return <RoundVote />;
}
