import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NOTIFICATION_SEVERITIES } from '../constants';
import { displayNotification } from '../store/notificationSlice';

export default function useDisplayNotification() {
  const dispatch = useDispatch();

  return useCallback((message, severity = NOTIFICATION_SEVERITIES.INFO) => {
    dispatch(
      displayNotification({
        message,
        severity,
      })
    );
  }, []);
}
