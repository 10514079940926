import { useRoutes } from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';
import MainLayout from '../layouts/MainLayout';
import Page404 from '../pages/404';
import Game from '../pages/Game';
import NewGame from '../pages/NewGame';
import QuestionsManager from '../pages/QuestionsManager';

const routes = {
  path: '',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <NewGame />,
    },
    {
      path: '/game',
      element: <Game />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ],
};

const adminRoutes = {
  path: 'admin',
  element: <AdminLayout />,
  children: [
    {
      path: 'questions-manager',
      element: <QuestionsManager />,
    },
  ],
};

export default function AppRoutes() {
  return useRoutes([routes, adminRoutes]);
}
