import { Paper, styled } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	wordBreak: 'break-word',
}));

export default StyledPaper;
