import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    isInitialized: false,
    doesGameExist: false,
    gameId: null,
    isHost: false,
    questionsStats: null,
    gameData: null,
    currentRound: null,
    playerData: null,
    players: {
      online: [],
      offline: [],
    },
    loading: false,
    loadingStates: {
      questionsStats: false,
      gameData: false,
      currentRound: false,
      playerData: false,
      players: false,
    },
  },
  reducers: {
    setIsInitialized: (state, action) => ({
      ...state,
      isInitialized: action.payload,
    }),
    setDoesGameExist: (state, action) => ({
      ...state,
      doesGameExist: action.payload,
    }),
    setGameId: (state, action) => ({
      ...state,
      gameId: action.payload,
    }),
    setQuestionsStats: (state, action) => ({
      ...state,
      questionsStats: action.payload,
    }),
    setGameData: (state, action) => ({
      ...state,
      gameData: action.payload,
    }),
    setCurrentRound: (state, action) => ({
      ...state,
      currentRound: action.payload,
    }),
    setLoadingState: (state, action) => ({
      ...state,
      loadingStates: {
        ...state.loadingStates,
        [action.payload.key]: action.payload.value,
      },
      loading: state.loadingStates.gameData || state.loadingStates.players,
    }),
    setPlayers: (state, action) => ({
      ...state,
      players: {
        online: action.payload.players.filter((player) => player.online),
        offline: action.payload.players.filter((player) => !player.online),
      },
      playerData: action.payload.players.find(
        (player) => player.uid === action.payload.uid
      ),
    }),
    setIsHost: (state, action) => ({
      ...state,
      isHost: action.payload,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const {
  setDoesGameExist,
  setIsInitialized,
  setGameId,
  setQuestionsStats,
  setGameData,
  setCurrentRound,
  setLoadingState,
  setPlayers,
  setIsHost,
  setLoading,
} = gameSlice.actions;

const gameReducer = gameSlice.reducer;

export const useIsInitialized = () =>
  useSelector((state) => state.game.isInitialized);
export const useDoesGameExist = () =>
  useSelector((state) => state.game.doesGameExist);
export const useGameId = () => useSelector((state) => state.game.gameId);
export const useOnlinePlayers = () =>
  useSelector((state) => state.game.players.online);
export const useOfflinePlayers = () =>
  useSelector((state) => state.game.players.offline);
export const useQuestionsStats = () =>
  useSelector((state) => state.game.questionsStats);
export const useGameData = () => useSelector((state) => state.game.gameData);
export const useCurrentRound = () =>
  useSelector((state) => state.game.currentRound);
export const usePlayerData = () =>
  useSelector((state) => state.game.playerData);
export const useIsHost = () => useSelector((state) => state.game.isHost);
export const useLoading = (key) =>
  useSelector((state) =>
    key ? state.game.loadingStates[key] : state.game.loading
  );

export default gameReducer;
