import getTrimmedValidator from '../utils/getTrimmedValidator';

export const VALIDATORS = {
  NAME: {
    required: true,
    valiate: getTrimmedValidator,
    minLength: 1,
    maxLength: 50,
  },
  ANSWER: {
    required: true,
    validate: getTrimmedValidator,
    minLength: 1,
    maxLength: {
      value: 120,
      message: 'Answer must be shorter than 120 characters',
    },
  },
  NAMES: {
    required: true,
    validate: (value) => {
      const names = value.trim().split(',');

      // Check that the are at least 2 names
      if (names.length < 2) {
        return 'You need to enter at least 2 names';
      }

      // Check that there are no empty names
      if (names.some((name) => name.trim() === '')) {
        return 'You cannot enter empty names';
      }

      // Check that there are no duplicate names
      const uniqueNames = [...new Set(names)];
      if (names.length !== uniqueNames.length) {
        return 'You cannot enter duplicate names';
      }

      return true;
    },
    minLength: 1,
    maxLength: 255,
  },
};
