import { signInAnonymously } from 'firebase/auth';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import {
  setUid,
  setUserError,
  setUserIsLoading,
} from '../../../store/authSlice';
import { auth } from '../../../utils/firebase';

export default function useAuthStateController() {
  const [user, userLoading, userError] = useAuthState(auth);
  const userId = user?.uid;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUid(userId ?? null));
  }, [userId]);

  useEffect(() => {
    dispatch(setUserIsLoading(userLoading));
  }, [userLoading]);

  useEffect(() => {
    dispatch(setUserError(userError ?? null));
  }, [userError]);

  useEffect(() => {
    signInAnonymously(auth);
  }, []);
}
