import { ref } from 'firebase/database';
import { useEffect } from 'react';
import { useListVals } from 'react-firebase-hooks/database';
import { useDispatch } from 'react-redux';
import { useUid } from '../../../store/authSlice';
import {
  setLoadingState,
  setPlayers,
  useGameId,
} from '../../../store/gameSlice';
import { realtimeDb } from '../../../utils/firebase';

export default function usePlayersController() {
  const uid = useUid();
  const gameId = useGameId();
  const dispatch = useDispatch();

  const [players, playersLoading, playersError] = useListVals(
    ref(realtimeDb, `games/${gameId}/players`),
    { keyField: 'uid' }
  );

  useEffect(() => {
    dispatch(
      setPlayers({
        players: players ?? [],
        uid,
      })
    );
  }, [players, uid]);

  useEffect(() => {
    dispatch(
      setLoadingState({
        key: 'players',
        value: playersLoading,
      })
    );
  }, [playersLoading]);
}
