import { ref } from 'firebase/database';
import { useEffect } from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useUid } from '../../../store/authSlice';
import {
  setDoesGameExist,
  setGameData,
  setGameId,
  setIsHost,
  setIsInitialized,
  setLoadingState,
  useDoesGameExist,
  useGameId,
  useIsInitialized,
} from '../../../store/gameSlice';
import { realtimeDb } from '../../../utils/firebase';

export default function useGameDataController() {
  const [query] = useSearchParams();
  const gameId = query.get('gameId');

  const uid = useUid();
  const dispatch = useDispatch();
  const isInitialized = useIsInitialized();
  const doesGameExist = useDoesGameExist();

  const [gameData, gameDataLoading, gameDataError] = useObjectVal(
    ref(realtimeDb, `games/${gameId}/data`)
  );

  useEffect(() => {
    if (!gameData) {
      return;
    }

    if (!isInitialized) {
      dispatch(setIsInitialized(true));
      dispatch(setDoesGameExist(true));
    }

    dispatch(setGameData(gameData));
  }, [gameData, isInitialized]);

  useEffect(() => {
    if (!gameData) {
      return;
    }

    const isHost = gameData.host === uid;

    dispatch(setIsHost(isHost));
  }, [gameData, uid]);

  useEffect(() => {
    dispatch(
      setLoadingState({
        key: 'gameData',
        value: gameDataLoading,
      })
    );
  }, [gameDataLoading]);

  useEffect(() => {
    if (gameDataError) {
      dispatch(setDoesGameExist(false));
      dispatch(setIsInitialized(true));
    }
  }, [gameDataError, isInitialized, doesGameExist]);

  useEffect(() => {
    dispatch(setGameId(gameId));
  }, [gameId]);
}
