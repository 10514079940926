import { styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { PAGE_MAX_WIDTH } from '../constants';
import PropTypes from '../propTypes';
import { setPagePaddingBottom } from '../store/appSlice';

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100vw',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  zIndex: 2,

  '.inner': {
    margin: 'auto',
    maxWidth: PAGE_MAX_WIDTH,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [theme.breakpoints.down(PAGE_MAX_WIDTH + 60)]: {
    '.inner': {
      paddingLeft: 0,
      paddingRight: 0,
      margin: 'initial',
      maxWidth: '100%',
    },
  },
}));

export default function PageBottomRow({ children }) {
  const ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    dispatch(setPagePaddingBottom(ref.current.clientHeight));

    return () => {
      dispatch(setPagePaddingBottom(0));
    };
  }, []);

  return (
    <Container ref={ref}>
      <div className="inner">{children}</div>
    </Container>
  );
}

PageBottomRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
