import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

const getPaperButtonStyles = (highlight, theme) => {
  const res = {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  };

  if (highlight) {
    res.backgroundColor = theme.palette.primary.main;
    res.color = theme.palette.getContrastText(theme.palette.primary.main);
  }

  return res;
};

const PaperButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})(({ theme, highlight }) => ({
  minHeight: 60,
  height: '100%',
  width: '100%',
  borderRadius: 0,
  textAlign: 'left',
  justifyContent: 'left',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textTransform: 'none',
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.standard,
  }),

  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },

  ...getPaperButtonStyles(highlight, theme),
}));

export default PaperButton;
